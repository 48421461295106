import styled from "@emotion/styled"
import React from "react"
import { Page } from "../components/page"

const ReferencesPage = () => {
  return (
    <Page
      title="References list for music influence on sports performance"
      meta={[{ name: "robots", content: "noindex" }]}
    >
      <article>
        <p>
          Aron, A., Hargens, T. A., Guill, S. G., Zedalis, D., Gregg, J. M.,
          Nickols-Richardson, S. M., &amp; Herbert, W. G. (2006).{" "}
          <a href="https://doi.org/10.1249/00005768-200605001-02413">
            Association between graded exercise test indicators of
            cardiovascular disease risks and peripheral vascular stiffness.
          </a>{" "}
          Medicine &amp; Science in Sports &amp; Exercise, 38(Supplement),
          S362-s363.
        </p>
        <p>
          Ballmann, C. G. (2021).{" "}
          <a href="https://doi.org/10.3390/jfmk6020033">
            The influence of music preference on exercise responses and
            performance: A review.
          </a>{" "}
          Journal of Functional Morphology and Kinesiology, 6(2), 33.{" "}
        </p>
        <p>
          Ballmann, C. G., Cook, G. D., Hester, Z. T., Kopec, T. J., Williams,
          T. D., &amp; Rogers, R. R. (2020).{" "}
          <a href="https://doi.org/10.3390/jfmk6010003">
            Effects of preferred and non-preferred warm-up music on resistance
            exercise performance.
          </a>{" "}
          Journal of Functional Morphology and Kinesiology, 6(1), 3.{" "}
        </p>
        <p>
          Ballmann, C. G., McCullum, M. J., Rogers, R. R., Marshall, M. R.,
          &amp; Williams, T. D. (2021).{" "}
          <a href="https://doi.org/10.1519/jsc.0000000000002981">
            Effects of preferred vs. Nonpreferred music on resistance exercise
            performance.
          </a>{" "}
          Journal of Strength and Conditioning Research, 35(6), 1650-1655.{" "}
        </p>
        <p>
          Chanda, M. L., &amp; Levitin, D. J. (2013).{" "}
          <a href="https://doi.org/10.1016/j.tics.2013.02.007">
            The neurochemistry of music.
          </a>{" "}
          Trends in Cognitive Sciences, 17(4), 179-193.{" "}
        </p>
        <p>
          Chen, Y., Chen, C., Tang, L., &amp; Chieng, W. (2023).{" "}
          <a href="https://doi.org/10.1109/aiiot58121.2023.10174324">
            Mid-distance running exercise assistance system via IoT and
            exercise-oriented music.
          </a>{" "}
          2023 IEEE World AI IoT Congress (AIIoT).{" "}
        </p>
        <p>
          Chtourou, H., Chaouachi, A., Hammouda, O., Chamari, K., &amp; Souissi,
          N. (2011).{" "}
          <a href="https://doi.org/10.1055/s-0031-1284398">
            Listening to music affects diurnal variation in muscle power output.
          </a>{" "}
          International Journal of Sports Medicine, 33(01), 43-47.{" "}
        </p>
        <p>
          Chtourou, H., Jarraya, M., Aloui, A., Hammouda, O., &amp; Souissi, N.
          (2012).{" "}
          <a href="https://doi.org/10.1016/j.scispo.2012.02.006">
            The effects of music during warm-up on anaerobic performances of
            young sprinters.
          </a>{" "}
          Science &amp; Sports, 27(6), e85-e88.{" "}
        </p>
        <p>
          Dorga, S. (2017). Music and sports - A psycholphysical effect.
          International Journal of Researches in Social Sciences and Information
          Studies, 5, 338-341.
        </p>
        <p>
          Filho, R. A., Oliveira, J. J., Zovico, P. V., Rica, R. L., Barbosa, W.
          A., Machado, A. F., Evangelista, A. L., Costa, E. C., Bergamin, M.,
          Baker, J. S., &amp; Bocalini, D. S. (2022).{" "}
          <a href="https://doi.org/10.1016/j.physbeh.2022.113931">
            Effects of music on psychophysiological responses during high
            intensity interval training using body weight exercises.
          </a>{" "}
          Physiology &amp; Behavior, 255, 113931.{" "}
        </p>
        <p>
          Gujjala, R., Latha, G. M., &amp; Devi, S. U. (2017).{" "}
          <a href="https://doi.org/10.36347/sjams.2017.v05i06.048">
            Psychophysical effects of Music in Exercise.
          </a>{" "}
          Scholars Journal of Applied Medical Sciences, 5, 2301-2305.{" "}
        </p>
        <p>
          Habibi, A., &amp; Damasio, A. (2014).{" "}
          <a href="https://doi.org/10.1037/pmu0000033">
            Music, feelings, and the human brain.
          </a>{" "}
          Psychomusicology: Music, Mind, and Brain, 24(1), 92-102.{" "}
        </p>
        <p>
          Jarraya, M., Chtourou, H., Aloui, A., Hammouda, O., Chamari, K.,
          Chaouachi, A., &amp; Souissi, N. (2012).{" "}
          <a href="https://doi.org/10.5812/asjsm.34543">
            The effects of music on high-intensity short-term exercise in well
            trained athletes.
          </a>{" "}
          Asian Journal of Sports Medicine, 3(4).{" "}
        </p>
        <p>
          Juslin, P. N. (2013).{" "}
          <a href="https://doi.org/10.3389/fpsyg.2013.00596">
            What does music express? Basic emotions and beyond.
          </a>{" "}
          Frontiers in Psychology, 4.{" "}
        </p>
        <p>
          Karageorghis, C. I., &amp; Priest, D. (2012).{" "}
          <a href="https://doi.org/10.1080/1750984x.2011.631027">
            Music in the exercise domain: A review and synthesis (Part II).
          </a>{" "}
          International Review of Sport and Exercise Psychology, 5(1), 67-84.{" "}
        </p>
        <p>
          Kawakami, A., Furukawa, K., Katahira, K., &amp; Okanoya, K. (2013).{" "}
          <a href="https://doi.org/10.3389/fpsyg.2013.00311">
            Sad music induces pleasant emotion.
          </a>{" "}
          Frontiers in Psychology, 4.{" "}
        </p>
        <p>
          Koelsch, S. (2015).{" "}
          <a href="https://doi.org/10.1093/eurheartj/ehv430">
            Music and the heart.
          </a>{" "}
          European Heart Journal, 36, 3043-3048.{" "}
        </p>
        <p>
          Koelsch, S. (2015).{" "}
          <a href="https://doi.org/10.1111/nyas.12684">
            Music-evoked emotions: Principles, brain correlates, and
            implications for therapy.
          </a>{" "}
          Annals of the New York Academy of Sciences, 1337(1), 193-201.{" "}
        </p>
        <p>
          MacNamara, B. N., Hambrick, D. Z., &amp; Oswald, F. L. (2013).{" "}
          <a href="https://doi.org/10.1037/e633262013-474">
            Deliberate practice and performance in music, games, sports,
            professions, and education: A meta-analysis.
          </a>{" "}
          PsycEXTRA Dataset.{" "}
        </p>
        <p>
          Maddigan, M. E., Sullivan, K. M., Halperin, I., Basset, F. A., &amp;
          Behm, D. G. (2019).{" "}
          <a href="https://doi.org/10.7717/peerj.6164">
            High tempo music prolongs high intensity exercise.
          </a>{" "}
          PeerJ, 6, e6164.{" "}
        </p>
        <p>
          Meglic, C. E., Orman, C. M., Rogers, R. R., Williams, T. D., &amp;
          Ballmann, C. G. (2021).{" "}
          <a href="https://doi.org/10.3390/jfmk6030064">
            Influence of warm-up music preference on anaerobic exercise
            performance in division I NCAA female athletes.
          </a>{" "}
          Journal of Functional Morphology and Kinesiology, 6(3), 64.{" "}
        </p>
        <p>
          Nixon, K. M., Parker, M. G., Elwell, C. C., Pemberton, A. L., Rogers,
          R. R., &amp; Ballmann, C. G. (2022).{" "}
          <a href="https://doi.org/10.3390/jfmk7020035">
            Effects of music volume preference on endurance exercise
            performance.
          </a>{" "}
          Journal of Functional Morphology and Kinesiology, 7(2), 35.{" "}
        </p>
        <p>
          Pain, M. A., Harwood, C., &amp; Anderson, R. (2011).{" "}
          <a href="https://doi.org/10.1123/tsp.25.2.212">
            Pre-competition imagery and music: The impact on flow and
            performance in competitive soccer.
          </a>{" "}
          The Sport Psychologist, 25(2), 212-232.{" "}
        </p>
        <p>
          Parizek, D., Sladicekova, K., Tonhajzerova, I., Veterník, M., &amp;
          Jakus, J. (2021).{" "}
          <a href="https://doi.org/10.2478/acm-2021-0001">
            The effect of music on heart rate variability (Review).
          </a>{" "}
          Acta Medica Martiniana, 21(1), 1-8.{" "}
        </p>
        <p>
          Pusey, C. G., Haugen, T., Høigaard, R., Ivarsson, A., Røshol, A. W.,
          &amp; Laxdal, A. (2023).{" "}
          <a href="https://doi.org/10.1177/20592043231174388">
            Put some music on: The effects of pre-task music tempo on arousal,
            affective state, perceived exertion, and anaerobic performance.
          </a>{" "}
          Music &amp; Science, 6, 205920432311743.{" "}
        </p>
        <p>
          Rogers, R. R., Williams, T. D., Nester, E. B., Owens, G. M., &amp;
          Ballmann, C. G. (2023).{" "}
          <a href="https://doi.org/10.3390/jfmk8010034">
            The influence of music preference on Countermovement Jump and
            maximal isometric performance in active females.
          </a>{" "}
          Journal of Functional Morphology and Kinesiology, 8(1), 34.{" "}
        </p>
        <p>
          Sharman, L., &amp; Dingle, G. A. (2015).{" "}
          <a href="https://doi.org/10.3389/fnhum.2015.00272">
            Extreme metal music and anger processing.
          </a>{" "}
          Frontiers in Human Neuroscience, 9.{" "}
        </p>
        <p>
          Sievers, B., Polansky, L., Casey, M., &amp; Wheatley, T. (2012).{" "}
          <a href="https://doi.org/10.1073/pnas.1209023110">
            Music and movement share a dynamic structure that supports universal
            expressions of emotion.
          </a>{" "}
          Proceedings of the National Academy of Sciences, 110(1), 70-75.{" "}
        </p>
        <p>
          Swaminathan, S., &amp; Schellenberg, E. G. (2015).{" "}
          <a href="https://doi.org/10.1177/1754073914558282">
            Current emotion research in music psychology.
          </a>{" "}
          Emotion Review, 7(2), 189-197.{" "}
        </p>
        <p>
          Szabo, A., &amp; Hoban, L. J. (2014). Psychological Effects of
          Fast-and Slow-Tempo Music Played during Volleyball Training in a
          National League Team. International Journal of Applied Sports
          Sciences, 16(2), 39-48.
        </p>
        <p>
          Tenenbaum, G., Lidor, R., Lavyan, N., Morrow, K., Tonnel, S.,
          Gershgoren, A., Meis, J., &amp; Johnson, M. (2004).{" "}
          <a href="https://doi.org/10.1016/s1469-0292(02)00041-9">
            The effect of music type on running perseverance and coping with
            effort sensations.
          </a>{" "}
          Psychology of Sport and Exercise, 5(2), 89-109.{" "}
        </p>
        <p>
          Thakur, A. M., &amp; Yardi, S. S. (2013). Effect of different types of
          music on exercise performance in normal individuals. Indian Journal of
          Physiology and Pharmacology, 57(4), 448-451.
        </p>
        <p>
          Van Dyck, E. (2019).{" "}
          <a href="https://doi.org/10.3389/fpsyg.2019.01145">
            Musical intensity applied in the sports and exercise domain: An
            effective strategy to boost performance?
          </a>{" "}
          Frontiers in Psychology, 10.{" "}
        </p>
        <p>
          Vuilleumier, P., &amp; Trost, W. (2015).{" "}
          <a href="https://doi.org/10.1111/nyas.12676">
            Music and emotions: From enchantment to entrainment.
          </a>{" "}
          Annals of the New York Academy of Sciences, 1337(1), 212-222.{" "}
        </p>
        <p>
          Waterhouse, J., Hudson, P., &amp; Edwards, B. (2010).{" "}
          <a href="https://doi.org/10.1111/j.1600-0838.2009.00948.x">
            Effects of music tempo upon submaximal cycling performance.
          </a>{" "}
          Scandinavian Journal of Medicine &amp; Science in Sports, 20(4),
          662-669.{" "}
        </p>
        <p>
          Wilkins, R. W., Hodges, D. A., Laurienti, P. J., Steen, M., &amp;
          Burdette, J. H. (2014).{" "}
          <a href="https://doi.org/10.1038/srep06667">
            Erratum: Network science and the effects of music preference on
            functional brain connectivity: From Beethoven to Eminem.
          </a>{" "}
          Scientific Reports, 4(1).{" "}
        </p>
        <p>
          Yeats, J., &amp; Smith, M. (2011).{" "}
          <a href="https://doi.org/10.2478/v10237-011-0068-x">
            High school volleyball coaches instructional approaches and
            perceptions to using athlete created pre-competition warm-up music.
          </a>{" "}
          Sport Science Review, 20(5-6), 127-143.{" "}
        </p>
        <p>
          Yeats, J. T., Rhoads, M. C., Smith, M. A., &amp; White, L. O. (2014).{" "}
          <a href="https://doi.org/10.2478/ssr-2014-0008">
            High school volleyball athletes’ perceptions of creating and using
            pre-competition warm-up music.
          </a>{" "}
          Sport Science Review, 23(3-4).{" "}
        </p>
        <p>
          Zhang, S. (2020).{" "}
          <a href="https://doi.org/10.4236/jbbs.2020.101005">
            The positive influence of music on the human brain.
          </a>{" "}
          Journal of Behavioral and Brain Science, 10(01), 95-104.{" "}
        </p>
      </article>
    </Page>
  )
}

export default ReferencesPage
